.Content {
    text-align: center;
    /* width: 1920px;
    height: 1920px;
    min-width: 1920px;
    min-height: 1920px; */
    position: relative;
    overflow: hidden;
    margin: auto;
}

.BackGround {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-box {
    width: 80%;
    height: 80%;
    max-width: 80vw;
    margin: 24px;
    max-height: min(800px, 80vh);
    background-color: '#000000';
    color: #ffffff
}

.swiper {
    height: 100%;
    width: 100%;
}

.qrcode-box {
    z-index: 999;
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 300px;
    /* aspect-ratio: 12/16; */
}

.ghost {
    opacity: .5;
    /* background-color: blue !important; */
    /* color: blue; */
}

.handle {
    /* position: absolute; */
    /* right: 0; */
    cursor: move;
    /* margin: 4px 4px 0 4px; */
    /* cursor: -webkit-grabbing; */
}

.slide-img-preview {
    height: 1000px;
    width: auto;
    max-height: 80px;
    max-width: 40%;
    background-color: black;
    margin-right: 12px;
    aspect-ratio: 1.4;
}

.slide-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* max-height: 100%;
    max-width: 40%; */
    /* margin-right: 12px; */
    /* aspect-ratio: 1; */
}

.message-text {
    z-index: 999;
    position: absolute;
    left: 0.1em;
    bottom: 0.1em;
    /* aspect-ratio: 12/16; */
}

.call-container {
    background-color: rgb(10, 10, 10);
    height: 100%;
}

.call-screen {
    width: 100%;
    height: 100%;
    background-color: lightslategrey;
}

.call-btn-container {
    height: 100px;
    width: 100px;
    /* border-radius: 50%; */
    /* background-color: lime; */
}

.call-btn {
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: lime;
    border: 0;
    outline: 0;
}

.call-btn[disabled=disabled], .call-btn:disabled {
    background-color: gray;
}

.call-btn-icon {
    height: 50px;
    width: 50px;
}

.endcall-btn {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: red;
}

.endcall-btn[disabled=disabled], .endcall-btn:disabled {
    background-color: gray;
}

.call-btn-icon {
    /* position: absolute; */
    /* top: 4; */
    /* left: 0; */
    /* right: auto; */
    height: 60px !important;
    width: 60px !important;
    padding-top: 8px;
    border: 0;
    outline: 0;
}