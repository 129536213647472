.status-bar {
    width: calc(100% + 8px);
    height: 100px;
    position: absolute;
    bottom: 0;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    margin: 0 -4px;
    /* padding-left: 24px; */
    padding-right: 24px;
    z-index: 2000;
    padding-bottom: 12px;
}

.help-btn {
    height: 100%;
    width: 50px;
    height: 50px;
    border: 5px solid rgb(200, 200, 200);
    border-radius: 50%;
    /* background-color: cyan; */
    /* color: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin: 0 4px 0 24px;
}

.help-btn>span {
    padding: 4px;
    font-weight: bold;
    /* line-height: 10px; */
}

.status-bar>span {
    margin: 4px;
    /* line-height: 10px; */
}

.stock-progress {
    width: 50px;
    height: 10px;
    margin: 0px 8px;
    background-color: white;
}

.stock-low {
    background-color: red !important;
}

.stock-mid {
    background-color: yellow !important;
}

.stock-high {
    background-color: lime !important;
}

.online {
    color: lime
}

.offline {
    color: red
}

.help-drawer {
    z-index: 1999;
}

.call-drawer {
    position: absolute;
    bottom: 100px;
    letter-spacing: 1.5px;
    /* left: 12px; */
    /* height: 400px; */
    width: 360px;
    z-index: 1999;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    background-color: rgba(50, 50, 50, 0.8);
    /* text-align: left; */
    padding: 36px 20px 24px 20px;
    color: white;
    font-size: 24px;
}

.call-status {
    font-size: 40px;
    padding-top: 20px;
}