html, body, #root {
  height: 100%;
  /* overflow: hidden; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

td {
  /* max-width: fit-content; */
  /* width: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

*, ::before, :after {
  box-sizing: border-box;
}

div {
  /* display: table-cell; */
  /* vertical-align: middle; */
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}